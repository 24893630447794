<template>
  <div>
    <error-box
      v-if="error"
      :loading="loading"
      @reload="load()" />

    <div
      v-else-if="loading"
      v-loading="loading"
      class="page-loader" />

    <template v-else-if="transaction">
      <portal to="actions">
        <div class="flex items-center">
          <el-button
            size="small"
            type="info"
            class="mr-5"
            icon="el-icon-printer"
            circle
            @click="openPrint" />
        </div>
      </portal>

      <panel>
        <data-list :list="mainList" />
      </panel>

      <panel title="Details" collapsable>
        <data-list :list="detailsList" />
      </panel>

      <panel title="Notes" scrollable collapsable>
        <el-table
          :data="transaction.notes"
          style="min-width: 500px; margin-bottom: 15px">
          <el-table-column label="Date/Time">
            <template slot-scope="scope">
              {{ scope.row.noteTime | date }}
            </template>
          </el-table-column>
          <el-table-column label="Added By">
            <template slot-scope="scope">
              {{ scope.row.addedBy }}
            </template>
          </el-table-column>
          <el-table-column
            label="Note"
            :align="'right'">
            <template slot-scope="scope">
              {{ scope.row.note }}
            </template>
          </el-table-column>
        </el-table>
      </panel>
    </template>
  </div>
</template>

<script>
import ErrorBox from '@/components/common/ui/ErrorBox'
import DataList from '@/components/common/ui/DataList'

export default {
  components: {
    ErrorBox,
    DataList
  },

  data () {
    return {
      error: null,
      loading: true
    }
  },

  computed: {
    id () {
      return this.$route.params.id
    },

    transaction () {
      return this.$store.getters['transactions/get'](this.id)
    },

    accountTransferList () {
      return [
        {
          label: 'Account Name',
          text: this.transaction?.toAccountName
        },
        {
          label: 'Account Number',
          text: this.transaction?.toAccountId ? this.$getAccountNumber(this.transaction.toAccountId) : ''
        },
        ...this.memoAndReferenceList,
        ...this.sourceAndSettledList
      ]
    },

    domesticWireList () {
      return [
        {
          label: 'Bank Name',
          text: this.transaction?.bankName
        },
        {
          label: 'Routing Number',
          text: this.transaction?.routingNumber
        },
        ...this.beneficaryList,
        ...this.furtherCreditList,
        ...this.memoAndReferenceList,
        ...this.sourceAndSettledList
      ]
    },

    internationalWireList () {
      let address = this.transaction?.intermediaryAddress?.address || ''
      let additionAddress = ''
      if (this.transaction?.intermediaryAddress?.city) additionAddress += this.transaction.intermediaryAddress.city
      if (this.transaction?.intermediaryAddress?.state) additionAddress += additionAddress ? ', ' + this.transaction.intermediaryAddress.state : this.transaction.intermediaryAddress.state
      if (this.transaction?.intermediaryAddress?.country) additionAddress += additionAddress ? ', ' + this.transaction.intermediaryAddress.country : this.transaction.intermediaryAddress.country
      if (this.transaction?.intermediaryAddress?.postal) additionAddress += additionAddress ? ', ' + this.transaction.intermediaryAddress.postal : this.transaction.intermediaryAddress.postal
      if (additionAddress) address = address + '<br>' + additionAddress

      return [
        {
          label: 'Bank Name',
          text: this.transaction?.bankName
        },
        {
          label: 'Swift',
          text: this.transaction?.swift
        },
        {
          subtitle: 'INTERMEDIARY BANK',
          spacer: true
        },
        {
          label: 'Bank Name',
          text: this.transaction?.intermediaryBankName
        },
        {
          label: 'Routing',
          text: this.transaction?.intermediaryBankRoutingNumber
        },
        {
          label: 'SWIFT',
          text: this.transaction?.intermediaryBankSwiftCode
        },
        {
          label: 'Reference',
          text: this.transaction?.intermediaryBankReference
        },
        {
          label: 'Address',
          html: address
        },
        ...this.beneficaryList,
        ...this.furtherCreditList,
        ...this.sourceAndSettledList
      ]
    },

    achList () {
      return [
        {
          label: 'Bank Name',
          text: this.transaction?.bankName
        },
        {
          label: 'Routing Number',
          text: this.transaction?.routingNumber
        },
        ...this.beneficaryList,
        ...this.memoAndReferenceList,
        ...this.sourceAndSettledList
      ]
    },

    checkList () {
      return [
        {
          label: 'Payer',
          text: this.transaction?.remoteDepositPayor?.nameOfSender
        },
        {
          label: 'Address',
          html: `
            ${this.transaction?.remoteDepositPayor?.address}<br>
            ${this.transaction?.remoteDepositPayor?.city}, ${this.transaction?.remoteDepositPayor?.state}, ${this.transaction?.remoteDepositPayor?.country}, ${this.transaction?.remoteDepositPayor?.postal}
          `
        },
        {
          label: 'Check Front',
          html: `<a class="image-link" href="${this.$server}files/transactions/${this.transaction.id}/${this.transaction.checkImageFront}" target="_blank">${this.transaction.checkImageFront}</a>`
        },
        {
          label: 'Check Bank',
          html: `<a class="image-link" href="${this.$server}files/transactions/${this.transaction.id}/${this.transaction.checkImageBack}" target="_blank">${this.transaction.checkImageBack}</a>`
        },
        ...this.memoAndReferenceList,
        ...this.sourceAndSettledList
      ]
    },

    assetsList () {
      return [
        {
          label: `${this.getCurrencyName(this.transaction.currencyName)} Deposit Wallet Address`,
          text: this.transaction?.contributionIncomingWalletAddress,
          condition: this.transaction?.entryType === 'Deposit'
        },
        {
          label: 'Pay to',
          text: this.transaction?.label,
          condition: this.transaction?.entryType !== 'Deposit'
        },
        {
          label: 'Pay to Wallet Address',
          text: this.transaction?.outgoingWalletAddress,
          condition: this.transaction?.entryType !== 'Deposit'
        },
        {
          label: 'Destination Tag',
          text: this.transaction?.outgoingWalletAddress,
          condition: this.transaction?.entryType !== 'Deposit'
        },
        ...this.sourceAndSettledList,
        ...this.memoAndReferenceList,
        {
          label: 'Note',
          text: `Send only ${this.getCurrencyName(this.transaction.currencyName)} to this wallet address. Any other asset type sent may be lost and unrecoverable.`
        }
      ]
    },

    tradeList () {
      return [
        ...this.sourceAndSettledList,
        ...this.memoAndReferenceList
      ]
    },

    debitCardList () {
      return [
        {
          label: 'Merchant',
          html: `
            ${this.transaction.merchant.merchant.merchantName}<br>
            ${this.transaction.merchant.merchant.merchantCity}, ${this.transaction.merchant.merchant.merchantState}, ${this.transaction.merchant.merchant.merchantCountry}, ${this.transaction.merchant.merchant.postalCode}<br>
            ${this.transaction.merchant.merchant.merchantCategory}
          `
        },
        ...this.memoAndReferenceList,
        ...this.sourceAndSettledList
      ]
    },

    systemList () {
      return [
        ...this.memoAndReferenceList,
        ...this.sourceAndSettledList
      ]
    },

    // RECURSIVE DATA

    furtherCreditList () {
      return [
        {
          label: 'Further Credit Account Name',
          text: this.transaction?.futherCreditAccName,
          spacer: true
        },
        {
          label: 'Further Credit Account Number',
          text: this.transaction?.futherCreditAccNumber
        }
      ]
    },

    beneficaryList () {
      return [
        {
          subtitle: 'BENEFICIARY',
          spacer: true
        },
        {
          label: 'Account Name',
          text: this.transaction?.accountName
        },
        {
          label: 'Account Number',
          text: this.transaction?.accountNumber
        },
        {
          label: 'Account Type',
          text: this.transaction?.accountType
        },
        {
          label: 'Address',
          html: this.transaction?.address ? `
            ${this.transaction?.address?.address}<br>
            ${this.transaction?.address?.city}, ${this.transaction?.address?.state}, ${this.transaction?.address?.country}, ${this.transaction?.address?.postal}
          ` : ''
        }
      ]
    },

    sourceAndSettledList () {
      return [
        {
          label: 'Source',
          text: this.transaction?.fundsSourceName,
          spacer: true
        },
        {
          label: 'Settled',
          text: this.transaction?.settledAt ? this.$date(this.transaction?.settledAt) : ''
        },
        {
          label: 'Settled Details',
          text: this.transaction?.settlementDetails
        },
        {
          label: 'Special Instructions',
          text: this.transaction?.specialInstructions,
          spacer: true
        }
      ]
    },

    memoAndReferenceList () {
      return [
        {
          label: 'Memo',
          text: this.transaction?.memo,
          spacer: true
        },
        {
          label: 'Reference',
          text: this.transaction?.reference
        }
      ]
    },

    mainList () {
      if (!this.transaction) return []

      const assetAmount = this.transaction.assetAmount ? parseFloat(this.transaction.assetAmount) : ''
      return [
        {
          label: 'Account',
          text: `****${this.transaction.accountId.substr(-3)}`
        },
        {
          label: 'Profile',
          text: this.transaction.profileNumber
        },
        {
          label: 'Created',
          text: this.$date(this.transaction.createdAt)
        },
        {
          label: 'Completed By',
          text: this.transaction.completedBy
        },
        {
          label: 'Entry Type',
          text: this.transaction.entryType
        },
        {
          label: 'Transaction Type',
          text: window.transactionTypeText[this.transaction.transactionType]
        },
        {
          label: 'Currency Type',
          text: this.transaction.currencyType
        },
        {
          label: 'Currency name',
          text: `${this.transaction.currencyName}`
        },
        {
          label: 'Transaction Amount',
          text: this.transaction.currencyType === 'Assets'
            ? assetAmount
            : this.$money(this.transaction.amount)
        },
        {
          label: 'Transaction Fee',
          text: this.$money(this.$toFixed(this.transaction.transactionFee))
        }
      ]
    },

    detailsList () {
      if (!this.transaction) return []

      if (this.transaction.transactionType === 'acc_to_acc') return this.accountTransferList
      if (['domestic_wire', 'domesticWire'].includes(this.transaction.transactionType)) return this.domesticWireList
      if (['international_wires', 'internationalWire'].includes(this.transaction.transactionType)) return this.internationalWireList
      if (this.transaction.transactionType === 'ach') return this.achList
      if (this.transaction.transactionType === 'checks') return this.checkList
      if (this.transaction.transactionType === 'Asset') return this.assetsList
      if (this.transaction.transactionType === 'Trade') return this.tradeList
      if (this.transaction.transactionType === 'DebitCard') return this.debitCardList
      if (this.transaction.transactionType === 'System') return this.systemList

      return []
    },

    currencies () {
      return this.$store.state.app.currencies.map(currency => ({
        id: currency.id,
        text: currency.currencyNickname
      }))
    }
  },

  created () {
    this.load()
  },

  methods: {
    async openPrint () {
      const loading = this.$loading()
      try {
        await this.$store.dispatch('transactions/action', {
          id: this.transaction?.id,
          action: 'Print'
        })
        var token = localStorage.getItem('token')

        window.open(`${this.$server}files/transactions/${this.transaction?.id}/${this.transaction?.id}-transaction.pdf?token=${token}`)
      } catch (e) {
        this.error = true
      } finally {
        loading.close()
      }
    },

    getCurrencyName (id) {
      const item = this.currencies.find(c => parseInt(c.id) === parseInt(id))
      return item?.text || 'N/A'
    },

    async load () {
      this.error = false
      this.loading = true
      try {
        const transaction = await this.$store.dispatch('transactions/get', this.id)
        this.$store.commit('page/setPageInfo', {
          title: `Transaction - ${transaction?.transactionId}`,
          back: { name: 'Transactions' }
        })
      } catch {
        this.error = true
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
